<template>
  <Modal title="发货" class-name="i-modal" v-model="isShow" loading>
    <Form
      ref="ShippingForm"
      :rules="ruleValidate"
      :model="ShippingForm"
      :label-width="80"
    >
      <FormItem prop="ExpressCode" label="物流公司:">
        <Select v-model="ShippingForm.ExpressCode" placeholder="请选择物流公司">
          <Option
            v-for="item in expressInfo"
            :key="item.Code"
            :value="item.Code"
            >{{ item.Name }}</Option
          >
        </Select>
      </FormItem>
      <FormItem prop="ShippingDate" label="发货时间:">
        <DatePicker
          placeholder="请选择发货时间"
          type="datetime"
          v-model="ShippingForm.ShippingDate"
          format="yyyy-MM-dd HH:mm:ss"
          style="width: 100%"
        ></DatePicker>
      </FormItem>
      <FormItem prop="ShippingCode" label="物流单号:">
        <Input
          placeholder="请输入物流单号"
          type="text"
          v-model="ShippingForm.ShippingCode"
          style="width: 100%"
        />
      </FormItem>
    </Form>

    <template slot="footer">
      <Button type="default" @click="isShow = false">取消</Button>
      <Button type="warning" :loading="loading" @click="handleShipments"
        >发货</Button
      >
    </template>
  </Modal>
</template>

<script>
import api from "@/api";
import moment from "moment";
export default {
  name: "shipping-modal",
  data() {
    return {
      isShow: false,
      loading: false,
      ruleValidate: {
        ExpressCode: [
          { required: true, trigger: "blur", message: "请选择物流公司" },
        ],
        ShippingDate: [
          {
            required: true,
            type: "date",
            trigger: "blur",
            message: "请选择发货时间",
          },
        ],
        ShippingCode: [
          { required: true, trigger: "blur", message: "请填写物流单号" },
        ],
      },
      ShippingForm: {
        ExpressCode: "",
        ExpressName: "",
        ShippingDate: "",
        ShippingCode: "",
      },
      expressInfo: [],
    };
  },
  watch: {
    isShow: function (val) {
      if (val) {
        this.GetExpressList();
        this.ShippingForm.ShippingDate = moment().format();
      } else {
        this.$refs.ShippingForm.resetFields();
      }
    },
  },
  methods: {
    // 发货
    handleShipments() {
      this.$refs["ShippingForm"].validate(async (res) => {
        if (res) {
          try {
            this.loading = true;
            this.ShippingForm.ExpressName = this.expressInfo.find(
              (item) => item.Code === this.ShippingForm.ExpressCode
            ).Name;
            await api.SendEMS({
              entity: this.ShippingForm,
            });
            this.$Message.success("发货成功");
            this.isShow = false;
            this.$emit("on-success", this.ShippingForm);
            this.loading = false;
            this.ShippingForm = {
              ExpressCode: "",
              ExpressName: "",
              ShippingDate: moment().format(),
              ShippingCode: "",
            };
          } catch (err) {
            this.$Message.error(err);
            this.loading = false;
          }
        } else {
          this.$Message.error("请填写正确的信息");
        }
      });
    },

    // 获取物流配置信息
    async GetExpressList() {
      try {
        const res = await api.GetExpressList({
          Status: 10,
          PageSize: 100,
        });
        if (res.Data) {
          this.expressInfo = res.Data.DataList;
        }
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
      }
    },
  },
};
</script>

<style lang="less"></style>
